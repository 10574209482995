<template>
  <default-layout>
    <v-container id="listing-management-edit" fluid tag="section">
      <listing-form />
    </v-container>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
import { mapState } from 'vuex';
const ListingForm = () => import('@/components/data-entry/listing/form/listing-form.vue');

export default {
  name: 'listing-edit',
  components: {
    DefaultLayout,
    ListingForm,
  },
  data() {
    return {
      isLoading: false,
      showAlert: false,
      messageAlert: null,
    };
  },
  computed: {
    ...mapState({}),
  },
  async created() {
    await this.$store.dispatch('listing/form/restoreInitialState');
    this.$store.commit('listing/form/SET_FORM_TYPE', 'EDIT');
    this.$store.commit('listing/form/SET_UUID', this.$route.params.uuid);
    this.$store.commit('listing/form/SET_AGENT_UUID', this.$route.query.agent_uuid);
    await this.$store.dispatch('listing/form/fetchBaseData');
  },
};
</script>
